import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginService } from './services/login.service';
import { CNReportingGuard, CNAuthoringGuard, ParaPassGuard, PGDGuard, UsageGuard, ParafolioCommanderGuard, SigChangeGuard, GlobalNotificationsGuard, FASTGuard, ParafolioHARTGuard, ParafolioEPRRGuard, ParafolioSWASTNQP1Guard, FullUsageGuard, PathwaysGuard } from './services/guards.service';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
    canActivate: [LoginService],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./page/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'home/pgd',
    loadChildren: () =>
      import('./page/pgd/pgd.module').then((m) => m.PgdPageModule),
    canActivate: [LoginService, PGDGuard],
  },
  {
    path: 'home/pgd/:type',
    loadChildren: () =>
      import('./page/pgd/pgd.module').then((m) => m.PgdPageModule),
    canActivate: [LoginService, PGDGuard],
  },
  {
    path: 'home/pgd/PGD/users/:type',
    loadChildren: () =>
      import('./page/users/users.module').then((m) => m.UsersPageModule),
    canActivate: [LoginService, PGDGuard],
  },
  {
    path: 'home/pgd/PMA/users/:type',
    loadChildren: () =>
      import('./page/users/users.module').then((m) => m.UsersPageModule),
    canActivate: [LoginService, PGDGuard],
  },
  {
    path: 'home/pgd/PGD/pgd-details/:GLID',
    loadChildren: () =>
      import('./page/pgd-details/pgd-details.module').then(
        (m) => m.PgdDetailsPageModule
      ),
    canActivate: [LoginService, PGDGuard],
  },
  {
    path: 'home/pgd/PMA/pgd-details/:GLID',
    loadChildren: () =>
      import('./page/pgd-details/pgd-details.module').then(
        (m) => m.PgdDetailsPageModule
      ),
    canActivate: [LoginService, PGDGuard],
  },
  {
    path: 'home/pgd/PGD/users/PGD/a-user/:ContactID',
    loadChildren: () =>
      import('./page/a-user/a-user.module').then((m) => m.AUserPageModule),
    canActivate: [LoginService, PGDGuard],
  },
  {
    path: 'home/pgd/PMA/users/PMA/a-user/:ContactID',
    loadChildren: () =>
      import('./page/a-user/a-user.module').then((m) => m.AUserPageModule),
    canActivate: [LoginService, PGDGuard],
  },
  {
    path: 'home/cns',
    loadChildren: () =>
      import('./page/cns/cns.module').then((m) => m.CNsPageModule),
    canActivate: [LoginService, CNReportingGuard],
  },
  {
    path: 'home/cns/cns-edit/:CNID',
    loadChildren: () =>
      import('./page/cns-edit/cns-edit.module').then(
        (m) => m.CnsEditPageModule
      ),
    canActivate: [LoginService, CNAuthoringGuard],
  },
  {
    path: 'home/cns/cns-index',
    loadChildren: () =>
      import('./page/cn-index/cn-index.module').then(
        (m) => m.CnIndexPageModule
      ),
    canActivate: [LoginService, CNAuthoringGuard],
  },
  {
    path: 'home/fast-news-authoring',
    loadChildren: () =>
      import('./page/fast-news-authoring/fast-news-authoring.module').then(
        (m) => m.FastNewsAuthoringPageModule
      ),
    canActivate: [false],
  },
  {
    path: 'home/fast-news-authoring/edit/:id',
    loadChildren: () =>
      import(
        './page/fast-news-authoring-edit/fast-news-authoring-edit.module'
      ).then((m) => m.FastNewsAuthoringEditPageModule),
    canActivate: [false],
  },
  {
    path: 'home/significant-changes',
    loadChildren: () =>
      import('./page/significant-changes/significant-changes.module').then(
        (m) => m.SignificantChangesPageModule
      ),
    canActivate: [LoginService, SigChangeGuard],
  },
  {
    path: 'home/significant-changes/details/:SCID',
    loadChildren: () =>
      import(
        './page/significant-changes-details/significant-changes-details.module'
      ).then((m) => m.SignificantChangesDetailsPageModule),
    canActivate: [LoginService, SigChangeGuard],
  },
  {
    path: 'home/usage-index',
    loadChildren: () =>
      import('./page/usage-index/usage-index.module').then(
        (m) => m.GuidelineUsagePageModule
      ),
    canActivate: [LoginService, UsageGuard],
  },
  {
    path: 'home/parapass-index',
    loadChildren: () =>
      import('./page/parapass-index/parapass-index.module').then(
        (m) => m.ParaPassPageModule
      ),
    canActivate: [LoginService, ParaPassGuard],
  },
  {
    path: 'home/cns/cns-details/:CNID',
    loadChildren: () =>
      import('./page/cns-details/cns-details.module').then(
        (m) => m.CnsDetailsPageModule
      ),
    canActivate: [LoginService, CNReportingGuard],
  },
  {
    path: 'home/parapass-index/users/:type',
    loadChildren: () =>
      import('./page/users/users.module').then((m) => m.UsersPageModule),
    canActivate: [LoginService, ParaPassGuard],
  },
  {
    path: 'home/cns/users/:type',
    loadChildren: () =>
      import('./page/users/users.module').then((m) => m.UsersPageModule),
    canActivate: [LoginService, CNReportingGuard],
  },
  {
    path: 'home/significant-changes/users/:type',
    loadChildren: () =>
      import('./page/users/users.module').then((m) => m.UsersPageModule),
    canActivate: [LoginService, SigChangeGuard],
  },
  {
    path: 'home/significant-changes/users/SIG/a-user/:ContactID',
    loadChildren: () =>
      import('./page/a-user/a-user.module').then((m) => m.AUserPageModule),
    canActivate: [LoginService, SigChangeGuard],
  },
  {
    path: 'home/usage-index/users/:type',
    loadChildren: () =>
      import('./page/users/users.module').then((m) => m.UsersPageModule),
    canActivate: [LoginService, UsageGuard],
  },
  {
    path: 'home/usage-index/users/USG/a-user/:ContactID',
    loadChildren: () =>
      import('./page/a-user/a-user.module').then((m) => m.AUserPageModule),
    canActivate: [LoginService, FullUsageGuard],
  },
  {
    path: 'home/cns/users/CNS/a-user/:ContactID',
    loadChildren: () =>
      import('./page/a-user/a-user.module').then((m) => m.AUserPageModule),
    canActivate: [LoginService, CNReportingGuard],
  },
  {
    path: 'home/parapass-index/users/PP/a-user/:ContactID',
    loadChildren: () =>
      import('./page/a-user/a-user.module').then((m) => m.AUserPageModule),
    canActivate: [LoginService, ParaPassGuard],
  },
  {
    path: 'home/sja-fast',
    loadChildren: () =>
      import('./page/sja-fast/sja-fast.module').then(
        (m) => m.SjaFastPageModule
      ),
    canActivate: [LoginService, FASTGuard],
  },
  {
    path: 'home/sja-fast/:property',
    loadChildren: () =>
      import('./page/sja-fast-detail/sja-fast-detail.module').then(
        (m) => m.SjaFastDetailPageModule
      ),
    canActivate: [LoginService],
  },
  // {
  //   path: 'cns-edit-modal-preview',
  //   loadChildren: () =>
  //     import('./modals/cns-edit-modal-preview/cns-edit-modal.module').then(
  //       (m) => m.CnsEditModalPageModule
  //     ),
  //   canActivate: [LoginService],
  // },
  {
    path: 'cns-send-notification-modal',
    loadChildren: () =>
      import(
        './modals/cns-send-notification-modal/cns-send-notification-modal.module'
      ).then((m) => m.CnsSendNotificationModalPageModule),
    canActivate: [LoginService, CNAuthoringGuard],
  },
  {
    path: 'cn-index',
    loadChildren: () =>
      import('./page/cn-index/cn-index.module').then(
        (m) => m.CnIndexPageModule
      ),
    canActivate: [LoginService, CNReportingGuard],
  },
  {
    path: 'home/global-notifications',
    loadChildren: () =>
      import(
        './page/global-notifications/global-notifications.module'
      ).then((m) => m.GlobalNotificationsPageModule),
    canActivate: [LoginService, GlobalNotificationsGuard],

  },
  {
    path: 'home/parafolio',
    loadChildren: () => import('./page/parafolio/parafolio.module').then( m => m.ParafolioPageModule),
    canActivate: [LoginService, ParafolioCommanderGuard],
  },
  {
    path: 'home/parafolio-nos',
    loadChildren: () => import('./page/nos-reports/nos-reports.module').then( m => m.NosReportsPageModule),
    canActivate: [LoginService, ParafolioCommanderGuard],
  },
  {
    path: 'home/parafolio/users/parafolio/a-user/:ContactID',
    loadChildren: () =>
      import('./page/a-user/a-user.module').then((m) => m.AUserPageModule),
    canActivate: [LoginService, ParafolioCommanderGuard],
  },
  {
    path: 'home/parafolio-hart',
    loadChildren: () => import('./page/parafolio-hart/parafolio-hart.module').then( m => m.ParafolioHartPageModule),
    canActivate: [LoginService, ParafolioHARTGuard],
  },
  {
    path: 'home/parafolio-eprr',
    loadChildren: () => import('./page/parafolio-eprr/parafolio-eprr.module').then( m => m.ParafolioEPRRPageModule),
    canActivate: [LoginService, ParafolioEPRRGuard],
  },
  {
    path: 'home/parafolio/users/parafolio-eprr/a-user/:ContactID',
    loadChildren: () =>
      import('./page/a-user/a-user.module').then((m) => m.AUserPageModule),
    canActivate: [LoginService, ParafolioEPRRGuard],
  },
  {
    path: 'home/parafolio/users/parafolio-hart/a-user/:ContactID',
    loadChildren: () =>
      import('./page/a-user/a-user.module').then((m) => m.AUserPageModule),
    canActivate: [LoginService, ParafolioHARTGuard],
  },
  {
    path: 'home/parafolio/users/parafolio-hart/a-user/:ContactID/competency/:CompetencyID',
    loadChildren: () =>
      import('./page/parafolio-competency/parafolio-competency.module').then( m => m.ParafolioCompetencyPageModule),
      canActivate: [LoginService, ParafolioHARTGuard],
  },
  {
    path: 'home/parafolio/users/parafolio-hart/a-user/:ContactID/competency/:CompetencyID/evidence/:EvidenceID',
    loadChildren: () =>
      import('./page/parafolio-evidence/parafolio-evidence.module').then( m => m.ParafolioEvidencePageModule),
      canActivate: [LoginService, ParafolioHARTGuard],
  },
  {
    path: 'home/parafolio/users/parafolio-eprr/a-user/:ContactID/evidence/:EvidenceID',
    loadChildren: () =>
      import('./page/parafolio-evidence/parafolio-evidence.module').then( m => m.ParafolioEvidencePageModule),
      canActivate: [LoginService, ParafolioEPRRGuard],
  },
  {
    path: 'home/parafolio-swast-nqp1',
    loadChildren: () => import('./page/parafolio/parafolio.module').then( m => m.ParafolioPageModule),
    canActivate: [LoginService, ParafolioSWASTNQP1Guard],
  },
  {
    path: 'home/parafolio/users/parafolio-swast-nqp1/a-user/:ContactID',
    loadChildren: () =>
      import('./page/a-user/a-user.module').then((m) => m.AUserPageModule),
    canActivate: [LoginService, ParafolioSWASTNQP1Guard],
  },
  {
    path: 'home/parafolio/users/parafolio-swast-nqp1/a-user/:ContactID/progress-check/:ProgressCheckID',
    loadChildren: () =>
      import('./page/parafolio-progress-check/parafolio-progress-check.module').then( m => m.ParafolioProgressCheckPageModule),
      canActivate: [LoginService, ParafolioSWASTNQP1Guard],
  },
  {
    path: 'home/parafolio/users/parafolio-swast-nqp1/a-user/:ContactID/progress-check/:ProgressCheckID/evidence/:EvidenceID',
    loadChildren: () =>
      import('./page/parafolio-evidence/parafolio-evidence.module').then( m => m.ParafolioEvidencePageModule),
      canActivate: [LoginService, ParafolioHARTGuard],
  },
  {
    path: 'home/pathways',
    loadChildren: () => import('./page/pathways/pathways.module').then( m => m.PathwaysPageModule),
    canActivate: [LoginService, PathwaysGuard],
  },
  {
    path: 'home/pathways/:PathwayID/feedback',
    loadChildren: () => import('./page/pathway-feedback/pathway-feedback.module').then( m => m.PathwayFeedbackPageModule),
    canActivate: [LoginService, PathwaysGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
