// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  staging: false,
};
// export const SERVER_URL = 'http://localhost:3005/TA-2/'
// export const SERVER_URL = 'https://shadow.aimernginx.co.uk/TA-2/'
// export const SERVER_URL = 'http://ec2-3-235-175-76.compute-1.amazonaws.com/TA-2/';
// export const SERVER_URL = 'https://class.aimernginx.co.uk/TA-TEST/';
export const SERVER_URL = 'https://class.aimernginx.co.uk/TA-2/';
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
