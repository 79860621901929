import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoginService } from './login.service';
import { CnsService } from './cns.service';
import * as FileSaver from 'file-saver';
import {isAfter, subYears} from 'date-fns';
import { SERVER_URL } from '../../environments/environment';
import moment from 'moment';
import { PGDData, PGDStat, Subscription, User } from '../types';

@Injectable({
  providedIn: 'root',
})
export class PgdService {
  constructor(
    private storage: Storage,
    private router: Router,
    private http: HttpClient,
    private loginService: LoginService,
    private cnsService: CnsService
  ) {
    this.servername = SERVER_URL;
    this.emptyDateTypes = {
      ViewDate: 'Not Viewed',
      ACKDate: 'No',
      QuizAttemptDate: 'Not Attempted',
      LastAccessDate: 'Not Accessed',
      FirstAccessDate: 'Not Accessed',
      ackdate: 'Not Acked',
      pp: '',
      EvidenceDate: 'Not added',
      ExpiryDate: '--',
    };
  }
  public userList = [];
  public userFilteredList = [];
  public PGDStats: PGDStat[] = [];
  public StaffGroups = [];
  public PGDData: PGDData[] = [];
  public servername;
  public loaded = false;
  private readonly emptyDateTypes;
  public testUsers = false;
  public inactiveUsers = false;
  public testUsersReload: boolean;

  private static logError(err) {
    console.error(err.message);
  }

  private static getCSVHeaders(fields: any) {
    return fields.map(({ heading }) => heading).join(',') + '\n';
  }

  public unload() {
    this.userList = [];
    this.PGDStats = [];
    this.PGDData = [];
    
    this.testUsers = false;
    this.loaded = false;
  }

  public reload() {
    this.unload();
    this.setup().catch(console.error);
  }

  public getStatByGLID(GLID) {
    return this.PGDStats.find((PGDStat: PGDStat) => PGDStat.GLID === GLID);
  }

  public getUsersForPgd(GLID) {
    const results: User[] = [];
    const PGDData = this.PGDData.filter((data: PGDData) => data.GLID === GLID);
    const PGDStat = this.getStatByGLID(GLID);

    for (const user of this.userFilteredList) {
      const result: User = {
        ContactID: user.ContactID,
        ESR: user.ESR,
        Name: user.Name,
        LoginName: user.LoginName,
        ManagementGroup: user.ManagementGroup,
        Region: user.Region,
        InActive: user.InActive,
        TestUser: user.TestUser,
        user,
        ViewTime: 0,
        ACKTime: 0,
        QuizAttemptTime: 0,
        PreviousAckTimes: null,
      };

      const thisUsersPGD: PGDData = PGDData.find(
        (data) => data.wyvernID === Number(user.ContactID)
      );

      if (thisUsersPGD) {
        result.ViewTime = thisUsersPGD.ViewTime || 0;
        result.ACKTime = thisUsersPGD.ACKTime || 0;
        result.QuizAttemptTime = thisUsersPGD.QuizAttemptTime || 0;
        if(PGDStat.GuidelineType === 'Prescription Medicine Authorisation') {
          result.PreviousAckTimes = thisUsersPGD.PreviousAckTimes != null ? JSON.parse(thisUsersPGD.PreviousAckTimes) : null;
          result.PMAExpired = this.PMAExpired(thisUsersPGD.ACKTime, result.PreviousAckTimes);
        }
      }

      results.push(result);
    }

    return results;
  }

  public PMAExpired(ackTime: string | 0 | null, previousAcks: string[] | null) {

    const hasAckTime = ackTime != null && ackTime != 0;
    const hasPreviousAcks = previousAcks != null && previousAcks.length > 0;

    switch(true) {

      case hasAckTime:

        // Check if the ack time is over a year ago
        const oneYearAgo = subYears(new Date(), 1);
        return isAfter(oneYearAgo, new Date(ackTime)) ? 1 : 0;
        
      case !hasAckTime && hasPreviousAcks:

        // If the user doesn't have an ack time but has previous acks, this indicates that the user's
        // authorisation has expired
        return 1;

    }

    // Return null if user has no ack time and no previous acks
    // This is to indicate that the user isn't authorised and has never been authorised
    return null;

  }

  public async setup() {
    return new Promise(async (resolve) => {
      try {
        if (!this.loaded) {
          await this.loadUsers();
          await this.loadPGDStats();
          await this.loadPGDData();
        }

        if (!this.loaded || this.testUsersReload) {
          this.userFilteredList = this.userList;
          if (!this.testUsers) {
            this.userFilteredList = this.userFilteredList.filter(
              (user) => !user.TestUser
            );
          }
          if (!this.inactiveUsers) {
            this.userFilteredList = this.userFilteredList.filter(
              (user) => !user.InActive
            );
          }

          for (const PDGStat of this.PGDStats) {
            PDGStat.acks = 0;
            PDGStat.views = 0;
            PDGStat.attempts = 0;
            PDGStat.usersInSG = 0;
            PDGStat.acksExpiredPMA = 0;
            PDGStat.AuthorisedPercent = '';
            PDGStat.string = '';
          }

          for (const user of this.userFilteredList) {
            let pgdsInSG = 0;
            let pmasInSG = 0;
            let statAckCount = 0;
            let statAckCountPMA = 0;
            let statExpiredAckCountPMA = 0;

            user.AckCount = 0;
            user.AckCountPMA = 0;
            user.Status = false;
            user.StatusPMA = false;

            // For each PGDStat, check if the user is in the same management group as the PGDStat -
            // if they are, then check the guideline type and increment the appropriate counter.
            // This is to find the total of users within the user's management group who have access to the PGD (pgdsInSG) and PMA (pmasInSG)
            for (const PGDStat of this.PGDStats) {

              const isInSG = this.isInSGCommaLists(user.ManagementGroup,PGDStat.SG);

              if(PGDStat.SG.includes('Registered Nurse') && user.ManagementGroup.includes('Registered Nurse')) {
                const test = ''
              }

              if (isInSG) {
                PGDStat.GuidelineType === 'Prescription Medicine Authorisation' ? pmasInSG++ : pgdsInSG++;
              }

              PGDStat.Title = this.htmlEntities(PGDStat.Title);
            }

            const userPGDData = this.PGDData.filter(
              (PGDData) => PGDData.wyvernID === Number(user.ContactID)
            );

            // PGDData contains objects which link users to a PGD/PMA along with metadata about the user's interaction with the PGD/PMA
            for (const PGDData of userPGDData) {

              const aPGDStat = this.getStatByGLID(PGDData.GLID);
              if(aPGDStat == null) { continue; } // skip to next PGDData record if no corresponding PGDStat is found

              let isInSG = false;
              let isPMA = false;

              isInSG = this.isInSGCommaLists(user.ManagementGroup, aPGDStat.SG);
              
              if (aPGDStat.GuidelineType ==='Prescription Medicine Authorisation') {
                isPMA = true;
              }

              // Peform these increments just for PMAs
              if (isPMA) {

                  // Check if the user has acknowledged the PMA within the last year
                  const PMAExpired = this.PMAExpired(PGDData.ACKTime, JSON.parse(PGDData.PreviousAckTimes)) == 1 ? true : false;

                  // Increment expired values
                  if(PMAExpired && isInSG) {
                    statExpiredAckCountPMA++;
                    aPGDStat.acksExpiredPMA = aPGDStat.acksExpiredPMA ? aPGDStat.acksExpiredPMA + 1 : 1;
                  }
                  
                  // Increment acks if not expired
                  if(PGDData.ACKTime && !PMAExpired && isInSG) {
                    user.AckCountPMA++;
                    statAckCountPMA++;
                    aPGDStat.acks = aPGDStat.acks ? aPGDStat.acks + 1 : 1;
                  }

              } else {

                // Peform this increment just for PGDs
                if(PGDData.ACKTime) {

                  user.AckCount++;
                  if (aPGDStat && isInSG) {
                    statAckCount++;
                    aPGDStat.acks = aPGDStat.acks ? aPGDStat.acks + 1 : 1;
                  }
                }
                
              }

              // Perform these increments for both PGDs and PMAs
              if(PGDData.ViewTime) {
                aPGDStat.views = aPGDStat.views ? aPGDStat.views + 1 : 1;
              }

              if (PGDData.QuizAttemptTime && isInSG) {
                aPGDStat.attempts = aPGDStat.attempts ? aPGDStat.attempts + 1 : 1;
              }

            }

            user.Status = `${statAckCount} of ${pgdsInSG}`;
            user.StatusPMA = `${statAckCountPMA} of ${pmasInSG}`;
            user.StatusExpiredPMA = `${statExpiredAckCountPMA} of ${pmasInSG}`;
          }

          this.StaffGroups = [];
          this.loadStaffGroups();

          for (const user of this.userFilteredList) {
            for (const PGD of this.PGDStats) {
              if (!PGD.usersInSG) {
                PGD.usersInSG = 0;
              }

              if (!PGD.acks) {
                PGD.acks = 0;
              }

              if (!PGD.views) {
                PGD.views = 0;
              }

              if (!PGD.attempts) {
                PGD.attempts = 0;
              }

              if (this.isInSGCommaLists(user.ManagementGroup, PGD.SG)) {
                PGD.usersInSG++;
              }
            }
          }

          // work out percentages
          for (const aPGD of this.PGDStats) {
            if (aPGD.usersInSG > 0) {
              aPGD.AuthorisedPercent = (
                (aPGD.acks / aPGD.usersInSG) *
                100
              ).toFixed(2);
            } else {
              aPGD.AuthorisedPercent = 0;
            }

            aPGD.stringify = JSON.stringify(aPGD, null, 2);
          }

          this.loaded = true;
          this.testUsersReload = false;
        }

        resolve(true);
      } catch (error) {
        console.log('error', error)
        // deal with problems here.
        resolve(false);
      }
    });
  }

  public includesPMAs() {
    for (const PGDData of this.PGDStats) {
      if (PGDData.GuidelineType === 'Prescription Medicine Authorisation')
        return true;
    }
    return false;
  }
  public isInSGCommaLists(listA, listB, matches = null) {
    let result = false;
    if (listA === undefined || listB === undefined) {
      return result;
    }

    if (listB.length === 0) {
      return true;
    }

    let aSplit = ',';
    let bSplit = ',';
    if (listA.indexOf('|') !== -1) {
      aSplit = '|';
    }
    if (listB.indexOf('|') !== -1) {
      bSplit = '|';
    }
    const userSGListA = listA.split(aSplit);
    const userSGListB = listB.split(bSplit);

    for (let aSG of userSGListA) {
      aSG = aSG.trim();
      for (let bSG of userSGListB) {
        bSG = bSG.trim();
        if (aSG === bSG) {
          if (matches) matches.push(aSG);
          result = true;
          break;
        }
      }
    }

    return result;
  }

  public userInPGD_SG(contactID, PGD_GLID) {
    let ManagementGroup;
    for (const aUser of this.userFilteredList) {
      if (Number(aUser.ContactID) === contactID) {
        ManagementGroup = aUser.ManagementGroup;
        break;
      }
    }
    const aPGDStat = this.getStatByGLID(PGD_GLID);
    if (ManagementGroup && aPGDStat.SG) {
      return this.isInSGCommaLists(ManagementGroup, aPGDStat.SG);
    } else {
      return false;
    }
  }

  public isInSG(managementGroup, SGList) {
    const userSGList = managementGroup.split(/\||,/);
    let result = false;

    for (let passedSG of SGList) {
      passedSG = passedSG.trim();
      for (let anSG of userSGList) {
        anSG = anSG.trim();
        if (anSG === passedSG) {
          result = true;
        }
      }
    }
    return result;
  }

  public getAUser(contactID) {
    return this.userFilteredList.find((user) => user.ContactID === contactID);
  }

  private getThePGDDetails(GLID) {
    let result;
    for (const aPGD of this.PGDStats) {
      if (aPGD.GLID === GLID) {
        result = aPGD;
        break;
      }
    }
    return result;
  }

  public async loadPGDData() {
    const body = new HttpParams()
      .set('trust', this.loginService.trust)
      .set('token', this.loginService.token)
      .set('contactID', this.loginService.contactID + "");

    await this.http
      .post(this.servername + 'aimerapi/class/PGD/PGDData', body.toString(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      .toPromise()
      .then(
        (data: { success; params }) => {
          if (data.success === true) {
            this.PGDData = data.params;
          }
        },
        (err) => {
          PgdService.logError(err);
          this.PGDData = [];
          if (err.status === 401) {
            this.loginService.logout();
          }
        }
      );

    return;
  }

  public isPGDLive(GLID) {
    const pgd = this.PGDStats.find((pgdStat) => pgdStat.GLID === GLID);

    if (!pgd) {
      return false;
    }

    if (pgd.remove) return false;
    return pgd.preview !== true;
  }

  public async getUserPGDs(contactID, livePGDs: boolean, PMAs: boolean) {
    let results = [];

    for (const PGDData of this.PGDData) {
      if (PGDData.wyvernID === Number(contactID)) {
        const isLive = this.isPGDLive(PGDData.GLID);

        PGDData.isLive = isLive;
        if (!livePGDs || isLive) {
          results.push(PGDData);
        }
      }
    }

    // add all the other PGDs

    for (const aPGD of this.PGDStats) {
      let aMatch = results.find((x) => x.GLID === aPGD.GLID);
      if (!aMatch) {
        //
        const isLive = this.isPGDLive(aPGD.GLID);
        let newOne = {
          wyvernID: contactID,
          GLID: aPGD.GLID,
          isLive: isLive,
          QuizAttemptTime: 0,
          ViewTime: 0,
          ACKTime: 0,
        };
        if (!livePGDs || isLive) {
          results.push(newOne);
        }
      }
    }

    for (const PGD of results) {
      const keys = Object.keys(PGD);

      for (const key of keys) {
        if (key === 'ViewTime' && !PGD[key]) {
          PGD[key] = 0;
        }

        if (key === 'ACKTime' && !PGD[key]) {
          PGD[key] = 0;
        }

        if (key === 'QuizAttemptTime' && !PGD[key]) {
          PGD[key] = 0;
        }
      }

      const details = this.getThePGDDetails(PGD.GLID);

      if (details) {
        PGD.Title = details.Title;
        PGD.SG = details.SG;
        let inSG = this.userInPGD_SG(parseInt(contactID), PGD.GLID);
        PGD.usersInSG = inSG;
        PGD.GuidelineType = details.GuidelineType;

        if (PGD.GuidelineType === 'Prescription Medicine Authorisation') {
          PGD.PMAExpired = this.PMAExpired(PGD.ACKTime, PGD.PreviousAckTimes);
          PGD.PreviousAckTimes = PGD.PreviousAckTimes != null ? PGD.PreviousAckTimes : null;
        }
      }
    }

    if (PMAs) {
      results = results.filter(
        (PGD) => PGD.GuidelineType === 'Prescription Medicine Authorisation'
      );
    } else {
      // remove PMAs
      results = results.filter(
        (PGD) => PGD.GuidelineType !== 'Prescription Medicine Authorisation'
      );
    }
    return results;
  }

  public async loadUsers() {
    let body = new HttpParams()
      .set('email', this.loginService.userName)
      .set('token', this.loginService.token)
      .set('trust', this.loginService.trust)
      .set('regions', this.loginService.regions.toString())
      .set('mustHaveSameSubscription', this.loginService.matchUsersWithSubscription + "")
      .set('contactID', this.loginService.contactID + "");

    await this.http
      .post(this.servername + 'aimerapi/class/PGD/AllUsers', body.toString(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      .toPromise()
      .then(
        (data: { success; params }) => {
          if (data.success === true) {
            this.userList = data.params;
          }
        },
        (err) => {
          PgdService.logError(err);
          this.userList = [];
          if (err.status === 401) {
            this.loginService.logout();
          }
        }
      );

    return;
  }

  public async resetPGD(aPGD) {
    const now = moment();
    const body = new HttpParams()
      .set('trust', this.loginService.trust)
      .set('token', this.loginService.token)
      .set('contactID', this.loginService.contactID + "")
      .set('GLID', aPGD.GLID)
      .set('userID', aPGD.wyvernID)
      .set('ResetTime', now.toISOString());

    await this.http
      .post(this.servername + 'aimerapi/class/PGD/PGDReset', body.toString(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      .toPromise()
      .then(
        (data: { success; params }) => {
          if (data.success === true) {
            aPGD.ACKTime = null;
          }
        },
        (err) => {
          PgdService.logError(err);
          this.PGDStats = [];
          if (err.status === 401) {
            this.loginService.logout();
          }
        }
      );

    return;
  }

  public async loadPGDStats() {
    const body = new HttpParams()
      .set('GLIDS', '["ALL"]')
      .set('trust', this.loginService.trust)
      .set('token', this.loginService.token)
      .set('contactID', this.loginService.contactID + "")
      .set('includeArchived', 'true')
      .set('includePreview', 'true');

    await this.http
      .post(this.servername + 'aimerapi/class/PGD/PGDStats', body.toString(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      .toPromise()
      .then(
        (data: { success; params }) => {
          if (data.success === true) {
            this.PGDStats = data.params;
          }
        },
        (err) => {
          PgdService.logError(err);
          this.PGDStats = [];
          if (err.status === 401) {
            this.loginService.logout();
          }
        }
      );

    return;
  }

  public loadStaffGroups() {
    if (this.StaffGroups.length > 0) {
      return;
    }

    this.StaffGroups = [{ name: 'Total', total: this.userFilteredList.length }];

    // Loop through each user and increment the total for each staff/management/pgd permission group
    for (const user of this.userFilteredList) {
      const MGs = user.ManagementGroup.split(/\||,/);

      for (let MG of MGs) {
        MG = MG.trim();

        const result = this.StaffGroups.find(({ name }) => name === MG);

        if (result) {
          result.total = result.total + 1;
        } else {
          this.StaffGroups.push({ name: MG, total: 1 });
        }
      }
    }
  }

  public exportCSV(fields: any, fileName: string, dataArray: any) {
    const csvHeaders = PgdService.getCSVHeaders(fields);
    let csvBody = '';

    for (const row of dataArray) {
      const csvRow = [];

      for (const { key } of fields) {
        const keyLower = key.toLowerCase();

        if (keyLower.includes('date')) {
          const date = row[key]
            ? moment(row[key]).format('ll')
            : this.emptyDateTypes[key];

          csvRow.push(`"${date}"`);
        } else if (keyLower.includes('time')) {
          const time = row[key]
            ? moment(row[key]).format('YYYY-MM-DD HH:mm:ss')
            : '';

          csvRow.push(`"${time}"`);
        } else if (keyLower.includes('cnacks')) {
          const acks = row[key] ? `${row[key]}` : 0;
          csvRow.push(`${acks} of ${this.cnsService.CNList.length}`);
        } else if (keyLower.includes('percentagecomp')) {
          csvRow.push(`"${row[key]}"`);
        } else {

          if(key.includes('PMAExpired')) {
            row[key] = row[key] == null ? "N/A" : row[key] == 1 ? "TRUE" : "FALSE";
          }

          const value = row[key] != null ? `${row[key]}` : ''; // e.g. blank name

          if (value === '') {
            // console.log('');
          }
          csvRow.push(`"${value}"`);
        }
      }

      csvBody += csvRow.join() + '\n';
    }

    const BOM = '\uFEFF';
    const csvData = BOM + csvHeaders + csvBody;
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, fileName);
  }

  public formatDate(date: string | number, type = '', format = 'lll'): string {
    return date ? moment(date).format(format ? format : 'lll') : this.emptyDateTypes[type];
  }
  public htmlEntities(astring: String) {
    // – vs. —
    astring = astring.replace('â', '—');
    return astring;
  }
}
