import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoginResponse, Subscription } from '../types';

@Injectable({
  providedIn: 'root',
})
export class LoginService implements CanActivate {
  public userName = '';
  public wyvernID = '';
  public errorMessage = '';
  public trust = '';
  public token = '';
  public contactID = -1;
  public servername;
  public isCFRAdmin = false;
  public features = [];
  public regions = [];
  public subscriptions = [];
  public matchUsersWithSubscription = false;
  public allTrusts = [
    "BRISTOW",
    "EEAST",
    "EMAS",
    "IOM",
    "JAS",
    "LAS",
    "NEOM",
    "NEAS",
    "NIAS",
    "NWAS",
    "SAS",
    "SCAS",
    "SECAMB",
    "SJA",
    "SJARS",
    "SWAST",
    "TST",
    "WAS",
    "WMAS",
    "YAS",
  ];

  private storageLoaded: Promise<boolean> = undefined;

  constructor(
    private storage: Storage,
    private router: Router,
    private http: HttpClient
  ) {
    this.servername = 'https://class.aimernginx.co.uk/';
    // this.servername = 'http://localhost:3011/';

    this.storageLoaded = new Promise((resolve) => {
      try {
        storage.ready().then(async () => {
          this.userName = await this.storage.get('TA-wyvernUserName');
          this.token = await this.storage.get('TA-token');
          this.contactID = await this.storage.get('TA-contactID');
          this.trust = await this.storage.get('TA-trust');
          this.isCFRAdmin = await this.storage.get('TA-CFR');
          this.features = await this.storage.get('TA-Features');
          let regions = await this.storage.get('TA-Regions');
          if (regions) {
            this.regions = regions;
          }
          this.matchUsersWithSubscription = await this.storage.get('TA-MatchUsersWithSubscription');

          this.logAccessTimes();

          resolve(true);
        });
      } catch (e) {
        resolve(false);
      }
    });

  }

  public async logAccessTimes() {

    try {

      // only log access times if the user is logged in
      if(!this.loginNeeded()) {
              
          const url = this.servername + 'TA-2/aimerapi/class/misc/logTAAccess';
          const body = new HttpParams()
              .set('contactID', this.contactID + "")
              .set('token', this.token)
              .set("trust", this.trust)

          const request = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              },
            body: body.toString()
            })

          const updateAccessResponse = await request.json();

          if (updateAccessResponse.success === true) {
            console.log("Logged access times");
          } else {
            console.log("Failed to log access times", updateAccessResponse.message)
          }
      }
    } catch (e) {
      console.log("Failed to log access times", e)
    }
  }

  public setUpHomeScreen(homepage) {

    for (let aFeature of this.features) {
      switch (aFeature) {
        case 'CN': {
          homepage.supportsReportingCNs = true;
          break;
        }
        case 'CN Authoring': {
          homepage.supportsAuthoringCNs = true;
          break;
        }
        case 'JRCALC Plus CN Authoring': {
          homepage.supportsAuthoringCNs = true;
          break;
        }
        case 'CFR CN Authoring': {
          homepage.supportsAuthoringCNs = true;
          break;
        }
        case 'CFR-CN Authoring': {
          homepage.supportsAuthoringCNs = true;
          break;
        }
        case 'CFR-CFR CN Authoring': {
          homepage.supportsAuthoringCNs = true;
          break;
        }
        case 'ParaPass': {
          homepage.supportsParaPass = true;
          break;
        }
        case 'PGD': {
          homepage.supportsPGDs = true;
          break;
        }
        case 'PMA': {
          homepage.supportsPMAs = true;
          break;
        }
        case 'Usage': {
          homepage.supportsUsage = true;
          break;
        }
        case 'CFR-Usage': {
          homepage.supportsUsageCFR = true;
          break;
        }
        case 'CFR-CN': {
          homepage.supportsReportingCNsCFR = true;
          break;
        }
        case 'ParaFolio1479-Usage':
        case 'SWAST MIC': {
          homepage.supportsParaFolioCommanderUsage = true;
          break;
        }
        case 'ParaFolio1531-Usage':
        case 'HART': {
          homepage.supportsParaFolioHARTUsage = true;
          break;
        }
        case 'ParaFolioEPRR':
        case 'SWAST HART and EPRR Instructor': {
          homepage.supportsParaFolioEPRRUsage = true;
          break;
        }
        case 'SWASFT Preceptorship NQP1': {
          homepage.supportsParaFolioSWASTPreceptorshipNQP1 = true;
          break;
        }
        case 'NWAS EPRR Evidence':
        case 'NWAS ERPP Evidence': {
          homepage.supportsNWASEPRRDashboard = true;
          break;
        }
        case 'FAST-FAST': {
          homepage.supportsFASTData = true;
          break;
        }
        case 'Pathways': {
          homepage.supportsPathways = true;
          break;
        }
        case 'Significant Changes': {
          homepage.supportsSigChanges = true;
          break;
        }
        case 'Admin': {
          // not sure what todo with this
          // homepage.supportsReportingCNsCFR = true;
          break;
        }
        case 'Class Internal': {
          homepage.supportsGlobalNotifications = true; // only for Trust Assistant Access subscribers
          break;
        }
        default: {
          console.log('Invalid choice');
          break;
        }
      }
    }
  }
  public ready(): Promise<boolean> {
    return this.storageLoaded;
  }

  public loginNeeded(): boolean {
    return this.token ? this.token.length <= 0 : true;
  }

  private logError(err) {
    console.error(err.message);
  }

  public CFR() {
    return this.isCFRAdmin;
  }

  public async login(userName: string, password: string): Promise<any> {
    return new Promise(async (resolve) => {
      this.errorMessage = '';
      const body = new HttpParams()
        .set('email', userName)
        .set('password', password);

      this.http
        .post(this.servername + 'classapi/SF/login', body.toString(), {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        })
        .subscribe((data:LoginResponse) => {

            if (data.auth) {

              console.log('login data: ', data);

              try {
                document.cookie = `taToken=${data.token};path=/;domain=trustassistant.co.uk`;
                document.cookie = `contactID=${data.contactID};path=/;domain=trustassistant.co.uk`;
              } catch (error) {
                console.log('Failed to set cookie', error);
              }

              const isAGeneralTAAccount = data.TA && data.TA.length > 0; // Typical user
              const isATestAccount = data.userName == "Test Ambulance";
              const specialClassAcc = data.TAFeatures && data.TAFeatures.includes('Class Internal');

              if (isAGeneralTAAccount || isATestAccount || specialClassAcc) {

                this.userName = userName;
                this.token = data.token;
                let parts = data.TA != null ? data.TA.split('|') : [];
                this.trust = parts[0];
                this.isCFRAdmin = parts.length > 1 && [parts[1] === 'CFR'] ? true : false;
                this.features = data.TAFeatures;
                this.regions = data.Regions;
                this.matchUsersWithSubscription = data.MatchUsersWithSubscription != null ? data.MatchUsersWithSubscription : false;
                this.contactID = data.contactID;
                this.subscriptions = data.subscriptions;

                this.storage.set('TA-wyvernUserName', userName);
                this.storage.set('TA-token', this.token);
                this.storage.set('TA-CFR', this.isCFRAdmin);
                this.storage.set('TA-trust', this.trust);
                this.storage.set('TA-Features', this.features);
                this.storage.set('TA-MatchUsersWithSubscription', this.matchUsersWithSubscription);
                this.storage.set('TA-Regions', this.regions);
                this.storage.set('TA-contactID', this.contactID);
                this.storage.set('TA-Subscriptions', data.subscriptions);

              } else {
                this.errorMessage =
                  'User is not authorised for Trust Assistant';
              }

            } else {
              if (data.body && data.body.ErrorMessage) {
                this.errorMessage = data.body.ErrorMessage;
              } else {
                this.errorMessage = 'Login Error';
              }
            }

            this.logAccessTimes();
            resolve(true);
          },
          (err) => {
            this.logError(err);
            this.errorMessage = err.message;
          }
        );
    });
  }

  public async logout() {
    await this.storage.set('wyvernUserName', '');
    await this.storage.set('TA-wyvernUserName', '');
    await this.storage.set('TA-token', '');
    await this.storage.set('TA-contactID', '');
    await this.storage.set('TA-trust', '');

    try {   
      document.cookie = `taToken=;path=/;domain=trustassistant.co.uk;Max-Age=0`;
      document.cookie = `contactID=;path=/;domain=trustassistant.co.uk;Max-Age=0`;
    } catch (error) {
     console.log('Failed to clear cookie', error); 
    }

    this.userName = '';
    this.trust = '';
    this.token = '';

    await this.router.navigate(['/home', {}]);
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      this.ready().then(() => {
        if (this.loginNeeded()) {
          this.router.navigate(['login']);
          resolve(false);
        }

        resolve(true);
      });
    });
  }
}
