import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LoginService } from './login.service';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class CNReportingGuard implements CanActivate {

  public features = [];
  public storageLoaded: Promise<boolean> = undefined;

  constructor(
    private router: Router,
    private storage: Storage,
  ) { 

    this.storageLoaded = new Promise((resolve) => {
      try {
        storage.ready().then(async () => {
          this.features = await this.storage.get('TA-Features');
          resolve(true);
        });
      } catch (e) {
        resolve(false);
      }
    });

  }
  
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (await this.storageLoaded) {
      this.features = await this.storage.get('TA-Features');
      if (this.features.includes('CN') || this.features.includes('CFR-CN')) {
        return true;
      } else {
        alert('You are not allowed to view this page. You are being redirected to the home page');
        this.router.navigate(['/']);
        return false;
      }
    } else {
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class CNAuthoringGuard implements CanActivate {

  public features = [];
  public storageLoaded: Promise<boolean> = undefined;

  constructor(
    private router: Router,
    private storage: Storage,
  ) { 

    this.storageLoaded = new Promise((resolve) => {
      try {
        storage.ready().then(async () => {
          this.features = await this.storage.get('TA-Features');
          resolve(true);
        });
      } catch (e) {
        resolve(false);
      }
    });

  }
  
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (await this.storageLoaded) {
      this.features = await this.storage.get('TA-Features');
      if (this.features.includes('CN Authoring') || this.features.includes('JRCALC Plus CN Authoring') || this.features.includes('CFR CN Authoring') || this.features.includes('CFR-CN Authoring') || this.features.includes('CFR-CFR CN Authoring')) {
        return true;
      } else {
        alert('You are not allowed to view this page. You are being redirected to the home page');
        this.router.navigate(['/']);
        return false;
      }
    } else {
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ParaPassGuard implements CanActivate {

  public features = [];
  public storageLoaded: Promise<boolean> = undefined;

  constructor(
    private router: Router,
    private storage: Storage,
  ) { 

    this.storageLoaded = new Promise((resolve) => {
      try {
        storage.ready().then(async () => {
          this.features = await this.storage.get('TA-Features');
          resolve(true);
        });
      } catch (e) {
        resolve(false);
      }
    });

  }
  
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (await this.storageLoaded) {
      this.features = await this.storage.get('TA-Features');
      if (this.features.includes('ParaPass')) {
        return true;
      } else {
        alert('You are not allowed to view this page. You are being redirected to the home page');
        this.router.navigate(['/']);
        return false;
      }
    } else {
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class PGDGuard implements CanActivate {

  public features = [];
  public storageLoaded: Promise<boolean> = undefined;

  constructor(
    private router: Router,
    private storage: Storage,
  ) { 

    this.storageLoaded = new Promise((resolve) => {
      try {
        storage.ready().then(async () => {
          this.features = await this.storage.get('TA-Features');
          resolve(true);
        });
      } catch (e) {
        resolve(false);
      }
    });

  }
  
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (await this.storageLoaded) {
      this.features = await this.storage.get('TA-Features');
      if (state.url.includes('pgd/PGD') && this.features.includes('PGD')) {
        return true;
      } else if (state.url.includes('pgd/PMA') && this.features.includes('PMA')) {
        return true;
      } else {
        alert('You are not allowed to view this page. You are being redirected to the home page');
        this.router.navigate(['/']);
        return false;
      }
    } else {
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class UsageGuard implements CanActivate {

  public features = [];
  public storageLoaded: Promise<boolean> = undefined;

  constructor(
    private router: Router,
    private storage: Storage,
  ) { 

    this.storageLoaded = new Promise((resolve) => {
      try {
        storage.ready().then(async () => {
          this.features = await this.storage.get('TA-Features');
          resolve(true);
        });
      } catch (e) {
        resolve(false);
      }
    });

  }
  
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (await this.storageLoaded) {
      this.features = await this.storage.get('TA-Features');
      if (this.features.includes('Usage') || this.features.includes('CFR-Usage')) {
        return true;
      } else {
        alert('You are not allowed to view this page. You are being redirected to the home page');
        this.router.navigate(['/']);
        return false;
      }
    } else {
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class FullUsageGuard implements CanActivate {

  public features = [];
  public storageLoaded: Promise<boolean> = undefined;

  constructor(
    private router: Router,
    private storage: Storage,
  ) { 

    this.storageLoaded = new Promise((resolve) => {
      try {
        storage.ready().then(async () => {
          this.features = await this.storage.get('TA-Features');
          resolve(true);
        });
      } catch (e) {
        resolve(false);
      }
    });

  }
  
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (await this.storageLoaded) {
      this.features = await this.storage.get('TA-Features');
      if (this.features.includes('Full Usage Log') || this.features.includes('CFR-Full Usage Log')) {
        return true;
      } else {
        alert("You don't have permission to view individual user logs.");
        // this.router.navigate(['/']);
        return false;
      }
    } else {
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ParafolioCommanderGuard implements CanActivate {

  public features = [];
  public storageLoaded: Promise<boolean> = undefined;

  constructor(
    private router: Router,
    private storage: Storage,
  ) { 

    this.storageLoaded = new Promise((resolve) => {
      try {
        storage.ready().then(async () => {
          this.features = await this.storage.get('TA-Features');
          resolve(true);
        });
      } catch (e) {
        resolve(false);
      }
    });

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (await this.storageLoaded) {
      this.features = await this.storage.get('TA-Features');
      if (this.features.includes('ParaFolio1479-Usage') || this.features.includes('SWAST MIC')) {
        return true;
      } else {
        alert('You are not allowed to view this page. You are being redirected to the home page');
        this.router.navigate(['/']);
        return false;
      }
    } else {
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ParafolioHARTGuard implements CanActivate {

  public features = [];
  public storageLoaded: Promise<boolean> = undefined;

  constructor(
    private router: Router,
    private storage: Storage,
  ) { 

    this.storageLoaded = new Promise((resolve) => {
      try {
        storage.ready().then(async () => {
          this.features = await this.storage.get('TA-Features');
          resolve(true);
        });
      } catch (e) {
        resolve(false);
      }
    });

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (await this.storageLoaded) {
      this.features = await this.storage.get('TA-Features');
      if (this.features.includes('ParaFolio1531-Usage') || this.features.includes('HART')) {
        return true;
      } else {
        alert('You are not allowed to view this page. You are being redirected to the home page');
        this.router.navigate(['/']);
        return false;
      }
    } else {
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ParafolioEPRRGuard implements CanActivate {

  public features = [];
  public storageLoaded: Promise<boolean> = undefined;

  constructor(
    private router: Router,
    private storage: Storage,
  ) { 

    this.storageLoaded = new Promise((resolve) => {
      try {
        storage.ready().then(async () => {
          this.features = await this.storage.get('TA-Features');
          resolve(true);
        });
      } catch (e) {
        resolve(false);
      }
    });

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (await this.storageLoaded) {
      this.features = await this.storage.get('TA-Features');
      if (this.features.includes('SWAST HART and EPRR Instructor')) {
        return true;
      } else {
        alert('You are not allowed to view this page. You are being redirected to the home page');
        this.router.navigate(['/']);
        return false;
      }
    } else {
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ParafolioSWASTNQP1Guard implements CanActivate {

  public features = [];
  public storageLoaded: Promise<boolean> = undefined;

  constructor(
    private router: Router,
    private storage: Storage,
  ) { 

    this.storageLoaded = new Promise((resolve) => {
      try {
        storage.ready().then(async () => {
          this.features = await this.storage.get('TA-Features');
          resolve(true);
        });
      } catch (e) {
        resolve(false);
      }
    });

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (await this.storageLoaded) {
      this.features = await this.storage.get('TA-Features');
      if (this.features.includes('SWASFT Preceptorship NQP1')) {
        return true;
      } else {
        alert('You are not allowed to view this page. You are being redirected to the home page');
        this.router.navigate(['/']);
        return false;
      }
    } else {
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class SigChangeGuard implements CanActivate {

  public features = [];
  public storageLoaded: Promise<boolean> = undefined;

  constructor(
    private router: Router,
    private storage: Storage,
  ) { 

    this.storageLoaded = new Promise((resolve) => {
      try {
        storage.ready().then(async () => {
          this.features = await this.storage.get('TA-Features');
          resolve(true);
        });
      } catch (e) {
        resolve(false);
      }
    });

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (await this.storageLoaded) {
      this.features = await this.storage.get('TA-Features');
      if (this.features.includes('Significant Changes') || this.features.includes('CFR-Significant Changes')) {
        return true;
      } else {
        alert('You are not allowed to view this page. You are being redirected to the home page');
        this.router.navigate(['/']);
        return false;
      }
    } else {
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class GlobalNotificationsGuard implements CanActivate {

  public contactID = '';
  public features = [];
  public storageLoaded: Promise<boolean> = undefined;

  constructor(
    private router: Router,
    private storage: Storage,
  ) { 

    this.storageLoaded = new Promise((resolve) => {
      try {
        storage.ready().then(async () => {
          this.contactID = await this.storage.get('TA-contactID');
          this.features = await this.storage.get('TA-Features');
          resolve(true);
        });
      } catch (e) {
        resolve(false);
      }
    });

  }
  
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (await this.storageLoaded) {
      this.features = await this.storage.get('TA-Features');
      if (this.features.includes('Class Internal')) {
        return true;
      } else {
        alert('You are not allowed to view this page. You are being redirected to the home page');
        this.router.navigate(['/']);
        return false;
      }
    } else {
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class FASTGuard implements CanActivate {

  public features = [];
  public storageLoaded: Promise<boolean> = undefined;

  constructor(
    private router: Router,
    private storage: Storage,
  ) { 

    this.storageLoaded = new Promise((resolve) => {
      try {
        storage.ready().then(async () => {
          this.features = await this.storage.get('TA-Features');
          resolve(true);
        });
      } catch (e) {
        resolve(false);
      }
    });

  }
  
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (await this.storageLoaded) {
      this.features = await this.storage.get('TA-Features');
      if (this.features.includes('FAST-FAST')) {
        return true;
      } else {
        alert('You are not allowed to view this page. You are being redirected to the home page');
        this.router.navigate(['/']);
        return false;
      }
    } else {
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class PathwaysGuard implements CanActivate {

  public features = [];
  public storageLoaded: Promise<boolean> = undefined;

  constructor(
    private router: Router,
    private storage: Storage,
  ) { 

    this.storageLoaded = new Promise((resolve) => {
      try {
        storage.ready().then(async () => {
          this.features = await this.storage.get('TA-Features');
          resolve(true);
        });
      } catch (e) {
        resolve(false);
      }
    });

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (await this.storageLoaded) {
      this.features = await this.storage.get('TA-Features');
      if (this.features.includes('Pathways')) {
        return true;
      } else {
        alert('You are not allowed to view this page. You are being redirected to the home page');
        this.router.navigate(['/']);
        return false;
      }
    } else {
      return false;
    }
  }
}
